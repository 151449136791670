import { Router } from '@angular/router';
import {
    Globals,
    ContentHeaders
} from '../common/globals';
import { KeyValueService } from './keyvalue.service';
import { TranslationService } from './translation.service';
import { SettingKeyValueModel } from './settingKeyValue.model';
import { map, tap, share, shareReplay, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable, Inject, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { isNullOrWhitespace } from '../common/functions';
import { Honeycomb } from './honeycomb-api/honeycomb-api';
import { NgxPermissionsService } from 'ngx-permissions';
import { HcLocalStorage } from '../common/local-storage';
import { of, Observable, pipe, lastValueFrom } from 'rxjs';
import { timingSafeEqual } from 'crypto';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { CompanyService } from './company.service';
import { MsalService } from '@azure/msal-angular';
import { BuildConfigService } from './build-config.service';
import { PublicClientApplication } from '@azure/msal-browser';


const jwtHelper = new JwtHelperService();

@Injectable()
export class AuthService {

    token: string;
    public uiRoles: string[] = [];
    private loadingRoles = false;

    avoidMsalLogin = false;
    msalAccountID: string = null;

    @Output()
    public reloadMenu = new EventEmitter();

    constructor(
        private http: HttpClient,
        public globals: Globals,
        public settingsService: KeyValueService,
        private translationService: TranslationService,
        private snack: MatSnackBar,
        private permissionService: NgxPermissionsService,
        private companyService: CompanyService,
        private hcLocalStorage: HcLocalStorage,
        private msalService: MsalService,
        private buildConfig: BuildConfigService,
        @Inject('UserController') private userController: Honeycomb.Tenant.Admin.IService.UserController,
        public router: Router) {

        this.UpdateUiRoles();
    }

    async multilogin(username: string, password: string) {
        let request = this.http.post(this.globals.GetUrlPrefix() + '/multilogin',
            { Username: username, Password: password }, { headers: ContentHeaders });
        let response = await request.toPromise().catch(r => null);

        if (response === null || response.length === 0) {
            return null;
        }

        let tokens = response as Array<string>;
        if (tokens.length === 1) {
            await this.setToken(tokens[0]);
            sessionStorage.setItem('loggedUserName', username);
            return true;
        }

        return tokens.map(t => {
            let decodedToken = jwtHelper.decodeToken(t);
            return { id: decodedToken.TenantID, name: decodedToken.TenantName, jwt: t };
        });
    }

    tenants() {
        let request = this.http.get(this.globals.GetUrlPrefix() + '/tenants');
        return request;
    }

    tenantsHash() {
        let request = this.http.get(this.globals.GetUrlPrefix() + '/tenantshash')
            .pipe(map((res: any) => res.json()));
        return request;
    }

    public setMsalAccountID(accountID: string) {
        this.msalAccountID = accountID;
    }


    async setMultiloginTenant( tenantID: number ) {
        sessionStorage.removeItem('id_token');
        // setToken
        console.log('changing tenant to: ' + tenantID);

        let tenant = this.globals.getMultiloginTenants().find( t => t.id === tenantID);
        await this.setToken(tenant.jwt);
        // the fact, that the action row is visible means, we are in the detail
        // it doesn't make any sense to try to show detail of an item of a different tenant
        // because it either doesn't exists, or it's different
        // in such a case always navigate to home
        if (this.globals.currentNavData().actionRowVisible) {

            this.globals.navigateAndResetHistory();
        }
    }

    async setToken(token: string, showTenantChangedMsg: boolean = false) {
        let decodedToken = jwtHelper.decodeToken(token);

        if (localStorage['tenantId'] !== decodedToken.TenantID && showTenantChangedMsg) {
            this.snack.open(this.translationService.instant('admin.web.tenant-changed'), null, {duration: 2000});
        }

        // set token property
        this.token = token;

        // store username and jwt token in local storage to keep user logged in between page refreshes
        sessionStorage.setItem('id_token', token);
        this.globals.setJWTToken(decodedToken);

        if (localStorage['tenantId'] !== decodedToken.TenantID) {
            // TenantID has changed after login
            this.translationService.get(this.globals.getLanguage());
        }

        localStorage['tenantId'] = decodedToken.TenantID;

        // reset cached UI roles when changing tenant
        this.hcLocalStorage.setUIRoles(null);

        let s = await lastValueFrom(this.settingsService.getAdminSettings().pipe(catchError((e => { console.error(e); return of (null);})))) as SettingKeyValueModel[];
        if (!!s) {
            s.push({ settingKey: 'tenantHash', settingValue: decodedToken.TenantHash });
            sessionStorage.setItem('settings', JSON.stringify(s));
        } else {
            sessionStorage.setItem('settings', null);
        }
        
        // Read UI-roles right after settings (otherwise the request won't have ApiURL)
        this.UpdateUiRoles().then(_ => {});
        
        await this.companyService.loadCompanies();
        await this.companyService.setCountryIsoCode();
        this.reloadMenu.emit();
    }


    externalLogin(token: string, provider: string): Observable<string[]> {

        const isLocalhost = window.location.hostname.indexOf('localhost') > -1 || window.location.hostname === 'tasker.local';
        const isDevRemoteDebug = window.location.hostname.indexOf('192.168.') > -1;
        
        var formData = new FormData();
        formData.append('accessToken', token);
        formData.append('scope', 'admin');


        if (isLocalhost) {
            return this.http.post<string[]>(`//localhost/api/GlobalAuth/external/${this.buildConfig.tenantID}/${provider}?scope=admin`,
                formData
            );
        }

        if (isDevRemoteDebug) {
            return this.http.post<string[]>(`//${window.location.hostname}/api/GlobalAuth/external/${this.buildConfig.tenantID}/${provider}?scope=admin`,
                formData
            );
        }

        return this.http.post<string[]>(`${this.buildConfig.externalAuthApiUri}/api/GlobalAuth/external/${this.buildConfig.tenantID}/${provider}?scope=admin`,
                formData
            );
    }

    getToken(): string {
        if (!isNullOrWhitespace(this.token)) { return this.token; }
        return this.globals.getToken();
    }

    // Logout the user
    logout() {
        sessionStorage.removeItem('profile');
        sessionStorage.removeItem('id_token');
        sessionStorage.removeItem('loggedUserName');

        this.logoutAzure();

        this.token = null;
        this.hcLocalStorage.setUIRoles(null);
        // Send the user back to the dashboard after logout
        this.globals.navigateAndResetHistory('/login').then(() => { location.reload(); });
    }

    logoutAzure() {
        if (this.buildConfig.azureLogin.enabled) {
            localStorage.setItem('avoidMsalLogin', 'true');
            let msalInstance: PublicClientApplication = this.msalService.instance as PublicClientApplication;
            if (!!this.msalAccountID) {
                this.msalService.logout({ logoutHint: this.msalAccountID });
            }
            else {
                this.msalService.logoutPopup();
            }
            msalInstance["browserStorage"].clear();
        }
    }

    public externalLoginEnabled(): boolean {
        return this.avoidMsalLogin && this.buildConfig.azureLogin.enabled;
    }

    public async UpdateUiRoles(): Promise<void> {
        if (this.loadingRoles || !this.loggedIn()) {
            return;
        }
        this.loadingRoles = true;

        // Load roles from store to handle page reload
        let roles = this.hcLocalStorage.getUIRoles();
        if (!roles || roles.length > 0) {
            roles = await lastValueFrom(this.userController.UIRoles().pipe(
                catchError(_ => {
                    this.loadingRoles = false;
                    return of([]);
                })));
            this.hcLocalStorage.setUIRoles(roles);
        }
        this.permissionService.loadPermissions(roles);
        this.uiRoles = roles;
        this.loadingRoles = false;
    }

    // Check whether the user is logged in or not
    loggedIn() {
        return !jwtHelper.isTokenExpired(this.globals.getToken());
    }
}

export class TenantShortModel {
    tenantHash: string;

    tenantName: string;

    tenantID: number;

    selected: boolean;
}
